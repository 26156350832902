<template>
  <div>
    <v-header></v-header>
    <template v-if="!loading">
      <div class="user-praise">
        <div @click="preview(index)" v-for="(item,index) in userPraise.records"
             class="user-praise-card">
          <div class="left">
            <van-image class="avatar" :src="item.avatarUrl" radius="1000"/>
          </div>
          <div class="right">
            <div class="nickName">{{ item.nickName }}</div>
            <div class="content">{{ item.content }}</div>
          </div>
          <i class="iconfont icon-go open-praise-btn"/>
        </div>
      </div>
      <div class="bottom-btn" @click="$router.push('/member')">
        <div class="button">和TA们一样，享受VIP的高效特权</div>
      </div>
    </template>
    <template v-else>
      <div class="user-praise" style="height: 100vh">
        <div class="user-praise-card skeleton-block" style="height: 60px" v-for="i in 8" :key="i"></div>
      </div>
    </template>
  </div>
</template>


-------------------- script --------------------

<script>
import {ImagePreview} from 'vant';
import {userPraiseV2Api} from "../../api/v2/userPraiseV2Api";
import {sleep} from "../../config/util";

export default {
  data() {
    return {
      loading: true,
      userPraise: {
        records: []
      }
    }
  },


  created() {
    this.loadUserPraises();
  },


  methods: {
    // 加载真实评价
    async loadUserPraises() {
      try {
        this.loading = true
        const res = await userPraiseV2Api.findPage({current: 1, size: 100000});
        this.userPraise.records = res.records;
      }catch (e){
        console.error(e)
        this.$toast.info(e.msg||'加载失败')
      }finally {
        await sleep(300)
        this.loading = false
      }
    },

    // 预览
    preview(index) {
      ImagePreview({
        images: this.userPraise.records.map(item => item.screenshotUrl),
        startPosition: index
      });
    }
  }
}
</script>


-------------------- styles --------------------

<style scoped lang="less">
.user-praise {
  flex-wrap: wrap;
  padding: 50px 16px 0 16px;
  background: #f3f5f7;
  height: calc(100vh - 50px - 100px);
  overflow-y: scroll;
}

.user-praise-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  background: #fff;
  padding: 32px 24px;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
  position: relative;

  .left {
    flex-shrink: 0;

    .avatar {
      height: 50px;
      width: 50px;
      border-radius: 100px;
    }

  }

  .right {
    flex-grow: 1;
    padding-left: 20px;
  }

  .nickName {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #151d36;
  }

  .content {
    font-size: 14px;
    margin-top: 10px;
    color: #666;
    word-break: break-all;
  }

  .open-praise-btn {
    position: absolute;
    bottom: 12px;
    right: 12px;
    font-size: 20px;
  }
}

.bottom-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  background: #f3f5f7;

  .button {
    height: 60px;
    width: 90%;
    border-radius: 100px;
    border: 2px solid #ee4a50;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ee4a50;
    background: #fff;
    font-size: 16px;
  }
}
</style>
